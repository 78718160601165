<template>
    <div class="order">
        <div class="title">
            <p>产品订单统计</p>
            <p>{{ month }}月</p>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="产品" prop="arriveTime" :formatter="productCategory">
            </el-table-column>
            <el-table-column label="数量" prop="orderTotal" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="总订单金额" prop="orderTotalAmount" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="实收总金额" prop="actualTotalAmount" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="原价" prop="formerTotalAmount" show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="操作" width="120" show-overflow-tooltip fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" style="color:#2370EB" @click="onSeeAll(scope.row)">
                        查看全部</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display:block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { aeOrder } from '@/api/user/data/ae-data';
export default {
    data() {
        return {
            month: '',
            tableData: [],
            userList: [],
            userType: sessionStorage.getItem('userType'),
            adminId: '',
        };
    },
    mounted() {
        let date = new Date();
        this.month = date.getMonth() + 1;
    },
    methods: {
        getData(id) {
            this.adminId = id;
            let data = {
                param: {
                    adminId: id,
                },
            };
            if (this.userType == 8 || this.userType == 9 || this.userType == 10) {
                data.param.departmentId = sessionStorage.getItem('departmentId');
            }
            aeOrder(data).then((res) => {
                // console.log(res);
                this.tableData = res.data.list;
            });
        },
        productCategory(row) {
            return this.$tableDataHandle.productCategory(row.productCategory);
        },
        onSeeAll(row) {
            let userType = sessionStorage.getItem('userType');

            this.$router.push({
                path: '/closed',
                query: {
                    productCategory: row.productCategory,
                    adminId: this.adminId,
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.order {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    p:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    p:nth-child(2) {
        width: 47px;
        height: 19px;
        background: #2370eb;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
    }
}
</style>
