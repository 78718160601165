<template>
  <div class="aeData">
    <div
      class="search-box"
      v-if="userType == 8 || userType == 9 || userType == 10"
    >
      <div class="search-one">
        <div class="left">
          <span>成员</span>
          <el-select
            v-model="adminId"
            size="small"
            style="width:180px"
            clearable
            placeholder="请选择"
            @change="getProgress()"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            style="margin-left:29px"
            size="small"
            @click="getProgress()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="data-one">
      <div class="order-list">
        <div class="order-title">
          <p>
            <span class="iconfont icon-hetong2" style=" color: #6d7df6;"></span
            >我的合同
          </p>
          <p class="month">{{ month }}月</p>
        </div>
        <div class="progress-box">
          <div class="quantity">
            <div class="title">
              <p>
                <span class="iconfont icon-hetong1"></span>
                合同数量
              </p>
              <p>
                <span style="color:#333333"
                  >{{ monthData.contractTotal }} /
                </span>
                <span style="color:#999999">{{ upperData.contractTotal }}</span>
                <span style="color:#999999">({{ upper }}月)</span>
              </p>
            </div>
            <div class="progress">
              <el-progress
                style="width:100%"
                :stroke-width="8"
                :percentage="
                  filterData(monthData.contractTotal, upperData.contractTotal)
                "
                color="#FEB858"
              ></el-progress>
            </div>
          </div>
          <div class="amount">
            <div class="title">
              <p>
                <span
                  class="iconfont icon-jinqian"
                  style="color:#FE8372"
                ></span>
                合同金额
              </p>
              <p>
                <span style="color:#333333"
                  >{{ monthData.contractTotalAmount }} /
                </span>
                <span style="color:#999999">{{
                  upperData.contractTotalAmount
                }}</span>
                <span style="color:#999999">({{ upper }}月)</span>
              </p>
            </div>
            <div class="progress">
              <el-progress
                style="width:100%"
                :stroke-width="8"
                :percentage="
                  filterData(
                    monthData.contractTotalAmount,
                    upperData.contractTotalAmount
                  )
                "
                color="#FE8372"
              ></el-progress>
            </div>
          </div>
          <div class="collection">
            <div class="title">
              <p>
                <span
                  class="iconfont icon-jinqian"
                  style="color:#2370EB"
                ></span>
                回款金额
              </p>
              <p>
                <span style="color:#333333"
                  >{{ monthData.paymentTotalAmount }} /
                </span>
                <span style="color:#999999">{{
                  upperData.paymentTotalAmount
                }}</span>
                <span style="color:#999999">({{ upper }}月)</span>
              </p>
            </div>
            <div class="progress">
              <el-progress
                style="width:100%"
                :stroke-width="8"
                :percentage="
                  filterData(
                    monthData.paymentTotalAmount,
                    upperData.paymentTotalAmount
                  )
                "
                color="#2370EB"
              ></el-progress>
            </div>
          </div>
          <div class="achievement">
            <div class="title">
              <p>
                <span
                  class="iconfont icon-shujukanban"
                  style="color:#6B7EF8"
                ></span>
                业绩
              </p>
              <p>
                <span style="color:#333333"
                  >{{ monthData.performanceTotalAmount }} /
                </span>
                <span style="color:#999999">{{
                  upperData.performanceTotalAmount
                }}</span>
                <span style="color:#999999">({{ upper }}月)</span>
              </p>
            </div>
            <div class="progress">
              <el-progress
                style="width:100%"
                :stroke-width="8"
                :percentage="
                  filterData(
                    monthData.performanceTotalAmount,
                    upperData.performanceTotalAmount
                  )
                "
                color="#6B7EF8"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="chart">
        <div class="order-title">
          <p>
            <span class="iconfont icon-hetong2" style="color:#EC5D5D"></span
            >合同信息
          </p>
          <div class="right-box">
            <span
              class="month"
              style="display: inline-block;width:47px;text-align:center"
            >
              {{ month }}月
            </span>

            <p class="but">
              <button
                @click="onIsColor(1)"
                :class="isColor == 1 ? 'color' : ''"
                class="iconfont icon-shuzhuangtu"
                style="font-size:12px;margin-right:0"
              ></button>
              <button
                @click="onIsColor(2)"
                :class="isColor == 2 ? 'color' : ''"
                class="iconfont icon-tubiao-zhexiantu"
                style="font-size:12px;margin-right:0"
              ></button>
            </p>
          </div>
        </div>

        <div style="flex:1">
          <Chart ref="column" />
        </div>
      </div>
    </div>
    <div class="order-box">
      <Order ref="order" />
    </div>
  </div>
</template>
<script>
import { aeBrief } from "@/api/user/data/ae-data";
import { roleList } from "@/api/user/clue";
import Chart from "./chart.vue";
import Order from "./order.vue";
export default {
  components: { Chart, Order },
  data() {
    return {
      month: "",
      upper: "",
      monthData: "",
      upperData: "",
      monthArr: [],
      isChart: true,
      isColor: 0,
      userList: [],
      adminId: "",
      userType: sessionStorage.getItem("userType"),
    };
  },
  mounted() {
    let date = new Date();
    this.month = date.getMonth() + 1;
    this.upper = date.getMonth();
    if (this.upper == 0) {
      this.upper = 12;
    }
    setTimeout(() => {
      this.getUserList();
    }, 50);
  },
  methods: {
    getUserList() {
      let data = {
        param: {
          roleTypeList: [1, 2, 3, 4, 8, 9, 10],
        },
      };
      roleList(data).then((res) => {
        this.userList = res.data;
        this.adminId = Number(sessionStorage.getItem("adminId"));
        this.getProgress();
      });
    },
    getProgress() {
      this.monthArr = [];
      for (let i = 1; i < 13; i++) {
        this.monthArr.push({
          currentMonth: i,
          contractTotal: 0,
          contractTotalAmount: 0,
          paymentTotalAmount: 0,
          performanceTotalAmount: 0,
        });
      }
      let data = {
        param: {
          adminId: this.adminId,
        },
      };
      if (this.userType == 8 || this.userType == 9 || this.userType == 10) {
        data.param.departmentId = sessionStorage.getItem("departmentId");
      }
      aeBrief(data).then((res) => {
        if (res.data.list.length > 0) {
          for (let i in this.monthArr) {
            for (let j in res.data.list) {
              if (
                this.monthArr[i].currentMonth == res.data.list[j].currentMonth
              ) {
                this.monthArr[i].contractTotal = res.data.list[j].contractTotal;
                this.monthArr[i].contractTotalAmount =
                  res.data.list[j].contractTotalAmount;
                this.monthArr[i].paymentTotalAmount =
                  res.data.list[j].paymentTotalAmount;
                if (res.data.list[j].performanceTotalAmount) {
                  this.monthArr[i].performanceTotalAmount =
                    res.data.list[j].performanceTotalAmount;
                }
              }
            }
          }
        }
        if (res.data.list.length > 0) {
          for (let i in this.monthArr) {
            if (this.monthArr[i].currentMonth == this.month) {
              this.monthData = this.monthArr[i];
            } else if (this.monthArr[i].currentMonth == this.upper) {
              this.upperData = this.monthArr[i];
            }
          }
        } else {
          this.monthData = {
            contractTotal: 0,
            contractTotalAmount: 0,
            paymentTotalAmount: 0,
            performanceTotalAmount: 0,
          };
          this.upperData = {
            contractTotal: 0,
            contractTotalAmount: 0,
            paymentTotalAmount: 0,
            performanceTotalAmount: 0,
          };
        }
        this.$refs.column.getData(this.monthArr, this.isColor);
        this.$refs.order.getData(this.adminId);
      });
    },
    onIsColor(i) {
      i === this.isColor ? (this.isColor = 0) : (this.isColor = i);
      this.$refs.column.getData(this.monthArr, this.isColor);
    },
    filterData(month, upper) {
      let result = 0;
      if (month > 0 && upper > 0) {
        result = parseFloat(((month / upper) * 100).toPrecision(12));
      } else {
        result = month;
      }
      if (result > 100) {
        return 100;
      } else {
        return result;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.aeData {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.data-one {
  width: 100%;
  height: 320px;
  display: flex;
  .order-list {
    width: 400px;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
  }
  .chart {
    flex: 1;
    height: 100%;
    margin-left: 16px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .order-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 17px 12px 17px 16px;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .iconfont {
      font-size: 20px;
      margin-right: 19px;
    }
    .month {
      width: 47px;
      height: 19px;
      background: #2370eb;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }
  }
  .progress-box {
    padding-top: 8px;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 12px 5px 16px;
      font-size: 14px;
      .iconfont {
        color: #6d7df6;
        margin-right: 12px;
      }
    }
    .progress {
      padding: 0 18px 20px 50px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    .but {
      margin-left: 8px;
      .color {
        background: #7e859f;
        color: #fff;
      }
    }
    button {
      width: 24px;
      height: 19px;
      background: #fff;
      outline: none;
      border: none;
      border: 1px solid #7e859f;
      cursor: pointer;
      color: #7e859f;
    }
    button:nth-child(1) {
      border-right: none;
      border-radius: 4px 0px 0px 4px;
    }
    button:nth-child(2) {
      border-radius: 0px 4px 4px 0px;
    }
  }
  .Tips {
    display: flex;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    padding-right: 14px;
    p {
      margin-left: 14px;
    }
    span {
      display: inline-block;
      width: 12px;
      height: 8px;
      border-radius: 2px;
      margin-right: 5px;
    }
  }
}
.order-box {
  flex: 1;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
}
.search-box {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  span {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    margin: 0 16px;
  }
  .search-one {
    padding: 10px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .left {
      button {
        background: #2370eb;
        border-radius: 2px;
        border-color: #2370eb;
        margin-right: 16px;
      }
    }
  }
}
</style>
<style>
.el-progress__text {
  display: none !important;
}
.el-progress-bar {
  padding-right: 0;
}
</style>
