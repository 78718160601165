import http from '@/utils/http.js'

// AE数据展示-合同信息统计
export function aeBrief(data) {
    return http({
        url: "/jasoboss/data/center/ae/contract/brief",
        method: "post",
        data
    })
}
// AE---产品订单统计（当月）
export function aeOrder(data) {
    return http({
        url: "/jasoboss/data/center/ae/order/brief",
        method: "post",
        data
    })
}

