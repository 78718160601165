<template>
    <div class="chart-box">
        <div id="main"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            data: '',
            type: '',
            contract: false,
            collection: false,
            achievement: false,
            currentMonthArr: [],
        };
    },
    mounted() {},
    methods: {
        onSwitch(i) {
            if (i == 1) {
                this.contract = !this.contract;
            } else if (i == 2) {
                this.collection = !this.collection;
            } else {
                this.achievement = !this.achievement;
            }
        },
        getData(data, i) {
            this.data = data;
            if (i == 1) {
                this.type = 'bar';
            } else {
                this.type = 'line';
            }
            let currentMonth = [];
            let contractTotalAmount = [];
            let paymentTotalAmount = [];
            let performanceTotalAmount = [];
            let contractTotal = [];
            for (let i in data) {
                currentMonth.push(data[i].currentMonth + '月');
                contractTotal.push(data[i].contractTotal);
                contractTotalAmount.push(data[i].contractTotalAmount);
                paymentTotalAmount.push(data[i].paymentTotalAmount);
                performanceTotalAmount.push(data[i].performanceTotalAmount);
            }
            document
                .getElementById('main')
                .removeAttribute('_echarts_instance_');
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);

            var that = this;
            // 绘制图表
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                        label: {
                            show: true,
                        },
                    },
                    formatter: function(params) {
                        //提示框自定义
                        return that.formatterTip(params);
                    },
                },
                calculable: true,
                legend: {
                    x: 'right',
                    data: ['合同金额', '回款金额', '业绩'],
                    itemGap: 10,
                    itemHeight: 10,
                    itemWidth: 15,
                    textStyle: {
                        //图例文字的样式
                        fontSize: 12,
                    },
                },
                grid: {
                    top: '10%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: currentMonth,
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        show: true,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],

                series: [
                    {
                        name: '合同数量',
                        color: '#6B7EF8',
                        type: 'bar',
                        data: contractTotal,
                        barWidth: 12,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                            color: 'rgba(0,0,0,0)',
                        },
                    },
                    {
                        name: '合同金额',
                        color: '#6B7EF8',
                        type: 'bar',
                        data: contractTotalAmount,
                        barWidth: 12,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                    {
                        name: '回款金额',
                        color: '#63D3BD',
                        type: 'bar',
                        barWidth: 12,
                        data: paymentTotalAmount,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                    {
                        color: '#FE8372',
                        name: '业绩',
                        type: 'bar',
                        barWidth: 12,
                        data: performanceTotalAmount,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                    {
                        name: '合同数量',
                        color: '#6B7EF8',
                        type: 'line',
                        data: contractTotal,
                        barWidth: 12,
                        itemStyle: {
                            color: 'rgba(0,0,0,0)',
                        },
                    },
                    {
                        name: '合同金额',
                        color: '#6B7EF8',
                        type: 'line',
                        data: contractTotalAmount,
                        barWidth: 12,
                    },
                    {
                        name: '回款金额',
                        color: '#63D3BD',
                        type: 'line',
                        barWidth: 12,
                        data: paymentTotalAmount,
                    },
                    {
                        color: '#FE8372',
                        name: '业绩',
                        type: 'line',
                        barWidth: 12,
                        data: performanceTotalAmount,
                    },
                ],
            };
            var optionBar = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                        label: {
                            show: true,
                        },
                    },
                },
                calculable: true,
                legend: {
                    x: 'right',
                    data: ['合同金额', '回款金额', '业绩'],
                    itemGap: 10,
                    itemHeight: 10,
                    itemWidth: 15,
                    textStyle: {
                        //图例文字的样式
                        fontSize: 12,
                    },
                },
                grid: {
                    top: '10%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: currentMonth,
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        show: true,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],

                series: [
                    {
                        name: '合同数量',
                        color: '#6B7EF8',
                        type: 'bar',
                        data: contractTotal,
                        barWidth: 12,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                            color: 'rgba(0,0,0,0)',
                        },
                    },
                    {
                        name: '合同金额',
                        color: '#6B7EF8',
                        type: 'bar',
                        data: contractTotalAmount,
                        barWidth: 12,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                    {
                        name: '回款金额',
                        color: '#63D3BD',
                        type: 'bar',
                        barWidth: 12,
                        data: paymentTotalAmount,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                    {
                        color: '#FE8372',
                        name: '业绩',
                        type: 'bar',
                        barWidth: 12,
                        data: performanceTotalAmount,
                        itemStyle: {
                            barBorderRadius: [20, 20, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                ],
            };
            var optionLine = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                        label: {
                            show: true,
                        },
                    },
                },
                calculable: true,
                legend: {
                    x: 'right',
                    data: ['合同金额', '回款金额', '业绩'],
                    itemGap: 10,
                    itemHeight: 10,
                    itemWidth: 15,
                    textStyle: {
                        //图例文字的样式
                        fontSize: 12,
                    },
                },
                grid: {
                    top: '10%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: currentMonth,
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        show: true,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],

                series: [
                    {
                        name: '合同数量',
                        color: '#6B7EF8',
                        type: 'line',
                        data: contractTotal,
                        barWidth: 12,
                        itemStyle: {
                            color: 'rgba(0,0,0,0)',
                        },
                    },
                    {
                        name: '合同金额',
                        color: '#6B7EF8',
                        type: 'line',
                        data: contractTotalAmount,
                        barWidth: 12,
                    },
                    {
                        name: '回款金额',
                        color: '#63D3BD',
                        type: 'line',
                        barWidth: 12,
                        data: paymentTotalAmount,
                    },
                    {
                        color: '#FE8372',
                        name: '业绩',
                        type: 'line',
                        barWidth: 12,
                        data: performanceTotalAmount,
                    },
                ],
            };
            // console.log(i);

            if (i == 0) {
                option && myChart.setOption(option);
            } else if (i == 1) {
                optionBar && myChart.setOption(optionBar);
            } else if (i == 2) {
                optionLine && myChart.setOption(optionLine);
            }
        },
        formatterTip(params) {
            //移除重复的数据
            for (var i = 0; i < params.length; i++) {
                for (var j = params.length - 1; j > i; j--) {
                    if (params[j].data == params[i].data) {
                        params.splice(j, 1);
                        break;
                    }
                }
            }

            var tip = params[0].axisValue + '<br/>';
            for (var i = 0; i < params.length; i++) {
                //这里是自己定义样式， params[i].marker 表示是否显示左边的那个小圆圈
                tip =
                    tip +
                    params[i].marker +
                    params[i].seriesName +
                    ' : ' +
                    params[i].value +
                    '<br/>';
            }

            return tip;
        },
        orderTotal(item) {
            // console.log(this.data, item);
            for (let i in this.data) {
                if (Number(item) - 1 == i) {
                    // console.log(this.data[i]);
                    return this.data[i].contractTotal;
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.chart-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
#main {
    flex: 1;
}
</style>
