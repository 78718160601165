<template>
  <div class="dataCenter">
    <AeData />
  </div>
</template>
<script>
import AeData from "../ae-data/aeData.vue";
export default {
  components: {
    AeData,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.dataCenter {
  width: 100%;
  height: 100%;
}
</style>
